import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { PortableText } from "@portabletext/react";

import Transition from "../components/Transition";
import { Grid, Wrapper } from "../ui/GridWrapper";
import { Caption, Heading2, Heading3 } from "../ui/Typography";
import { SEO } from "../components/SEO";

const ExpertWrapper = styled.div`
  grid-column: 1 / -1;
  padding: 10px 0 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.color};
  &:nth-last-child(1) {
    padding: 10px 0 0;
    border-bottom: none;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 10px 0 10px;
  }
`;
const TitleWrapper = styled.div`
  grid-column: 1 / span 2;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    margin: 0 -5px 0 0;
    border-right: 1px solid ${(props) => props.theme.colors.color};
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 1 / -1;
    &:after {
      border-right: none;
    }
  }
`;
const DescWrapper = styled.div`
  grid-column: 3 / span 4;
  position: relative;
  padding-right: 20px;
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    margin: 0 -5px 0 0;
    border-right: 1px solid ${(props) => props.theme.colors.color};
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 1 / -1;
    padding-right: 0;
    padding-bottom: 5px;
    &:after {
      border-right: none;
    }
  }
`;
const MediaWrapper = styled.div`
  grid-column: 7 / span 6;
  & > div {
    width: 100%;
    height: 442px;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    grid-column: 1 / -1;
    padding-top: 10px;
    & > div {
      height: 246px;
    }
  }
`;

const ExpertiseItem = ({ data }) => {
  const components = {
    block: {
      normal: ({ children }) => <Heading3 grid="1 / -1">{children}</Heading3>,
    },
  };

  return (
    <ExpertWrapper>
      <Grid>
        <TitleWrapper>
          <Heading2>{data.title}</Heading2>
        </TitleWrapper>
        <DescWrapper>
          {/* <Heading3 grid="1 / -1">{data.description}</Heading3> */}
          {data._rawDescription && (
            <PortableText
              value={data._rawDescription}
              components={components}
            />
          )}
        </DescWrapper>
        <MediaWrapper>
          {data.media[0]?._type === "img" ? (
            <GatsbyImage
              image={data.media[0].asset.gatsbyImageData}
              alt={data.media[0].alt}
              layout="fullWidth"
              draggable={false}
            />
          ) : (
            <ReactPlayer
              url={data.media[0].vimeoURL}
              playing={data.media[0].autoplay}
              loop={data.media[0].loop}
              muted={data.media[0].muted}
              controls={data.media[0].controls}
              playsinline={data.media[0].autoplay}
              pip={false}
              width={"100%"}
              height={"auto"}
              config={{
                vimeo: {
                  playerOptions: {
                    byline: false,
                    portrait: false,
                    title: false,
                    responsive: true,
                    color: "EB3329",
                  },
                },
              }}
            />
          )}
        </MediaWrapper>
      </Grid>
    </ExpertWrapper>
  );
};

function Expertise() {
  const query = useStaticQuery(graphql`
    query Exptertise {
      allSanityExpertises(sort: { fields: orderRank }) {
        edges {
          node {
            id
            title
            _rawDescription(resolveReferences: { maxDepth: 10 })
            media {
              ... on SanityImg {
                _key
                _type
                alt
                caption
                asset {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
              ... on SanityVideo {
                _key
                _type
                alt
                autoplay
                caption
                controls
                loop
                muted
                vimeoURL
              }
            }
          }
        }
      }
    }
  `);
  const data = query.allSanityExpertises.edges;
  // console.log(data);
  return (
    <Transition>
      <Wrapper grid="1 / -1" border>
        <Caption>Our Expertise</Caption>
      </Wrapper>
      {data.map((item, index) => {
        return <ExpertiseItem data={item.node} key={index} />;
      })}
    </Transition>
  );
}

export default Expertise;

export const Head = () => <SEO title="Expertise | Pal" />;
